import ReactDOM from "react-dom";
import React from "react";
import { Helmet } from "react-helmet";
import AboutUs from "./pages/AboutUs";
import Exam from "./pages/Exam";
import ContactUs from "./pages/ContactUs";
import Jobs from "./pages/Jobs";
import Policies from "./pages/Policies";
import RefundPolicy from "./pages/RefundPolicy";
import TremsAndConditions from "./pages/TremsAndConditions";
import Footer from "./includes/Footer";
import Header from "./includes/Header2";
import ImportantPoints from "./pages/ImportantPoints";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Home2 from "./pages/Home";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import "./css/style.css";
import "bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cart from "./pages/Cart";
import Courses from "./pages/Courses";
import CoursesDetails from "./pages/CoursesDetails";
import PageNotFound from "./pages/PageNotFound";
import Testimonials from "./pages/Testimonials";
import Profile from "./pages/Profile";
import EditProfile from "./pages/EditProfile";
import SuccessStories from "./pages/SuccessStories";
import DigitalFeatures from "./pages/DigitalFeatures";
import AuthComponent from "./components/auth/AuthComponent";
import LearningApp from "./pages/LearningApp";
import FormSuccess from "./pages/formsuccess";
import ScrollToTop from "./components/scrollToTop";
import Articles from "./pages/Articles";
import ArticleDetails from "./pages/ArticleDetails";
import PreviousYearPapers from "./pages/PreviousYearPapers";

import EcomCoursesDetails from "./pages/Ecom/CourseDetails";
import EcomCourses from "./pages/Ecom/Courses";
import EcomCart from "./pages/Ecom/EcomCart";
import EcomOrder from "./pages/Ecom/EcomOrder";
import RefundOrders from "./pages/Ecom/RefundOrders";
import EcomSuccess from "./pages/Ecom/EcomSuccess";
import EcomInstallmentStatus from "./pages/Ecom/EcomInstallmentStatus";
import EcomCheckout from "./pages/Ecom/EcomCheckout";
import EcomCheckoutCopy from "./components/propelld/EcomCheckoutCopy";
import loadingimg from "./images/loading.gif";

import FreeResourceJeeMain from "./pages/FreeResourceJeeMain";
import FreeResourceJeeAdvanced from "./pages/FreeResourceJeeAdvanced";
import FreeResourceNeetUG from "./pages/FreeResourceNeetUG";

import Pncf from "./pages/Pncf";
import NeetEnthusiast from "./pages/NeetEnthusiast";
import JeeMainEnthusiast from "./pages/JeeMainEnthusiast";
import JeeAdvancedEnthusiast from "./pages/JeeAdvancedEnthusiast";
import JeeAdvancedLeader from "./pages/JeeAdvancedLeader";
import NeetLeader from "./pages/NeetLeader";
import PNCFCLASS6 from "./pages/PNCFCLASS6";
import PNCFCLASS7 from "./pages/PNCFCLASS7";
import PNCFCLASS8 from "./pages/PNCFCLASS8";
import PNCFCLASS9 from "./pages/PNCFCLASS9";
import PNCFCLASS10 from "./pages/PNCFCLASS10";

/* Olympiad */
import OLYMPIADCLASS6 from "./pages/OLYMPIADCLASS6";
import OLYMPIADCLASS7 from "./pages/OLYMPIADCLASS7";
import OLYMPIADCLASS8 from "./pages/OLYMPIADCLASS8";
import OLYMPIADCLASS9 from "./pages/OLYMPIADCLASS9";
import OLYMPIADCLASS10 from "./pages/OLYMPIADCLASS10";

import JeeAdvNurture from "./pages/JeeAdvNurture";
import JeeMainNuture from "./pages/JeeMainNuture";
import NeetNuture from "./pages/NeetNuture";
import FoundationIIT from "./pages/FoundationIIT";
import FoundationMedical from "./pages/FoundationMedical";

/* Online Test Series */
import OnlineTestSeriesNeet from "./pages/OnlineTestSeriesNeet";
import OnlineTestSeriesJeeMain from "./pages/OnlineTestSeriesJeeMain";
import OnlineTestSeriesJeeMainAdvanced from "./pages/OnlineTestSeriesJeeAdvance";
import OnlineTestSeriesPncf from "./pages/OnlineTestSeriesJeePncf";

import FreeResourceNewJeeMain from "./pages/FreeResourceNewJeeMain";
import PypjeeMain2022 from "./pages/PypjeeMain2022";
import PypNeetPapers2023 from "./pages/PypNeetPapers2023";
import NeetUgManipurPapers2023 from "./pages/NeetUgManipurPapers2023";
import FreeResourceJeeAdvanced2022 from "./pages/FreeResourceJeeAdvanced2022";
import Success from "./components/propelld/Success";
import ThankyouPage from "./components/propelld/thankyouPage";
import CourseInfromation from "./components/propelld/CourseInfromation";
import FreemiumMajorCourse from "./components/Ecom/FreemiumMajorCourse";
import ResultPage from "./pages/JeeMainResult/ResultPage";
import Webinars from "./pages/webinars/Webinars";
import WebinarsLiveSessions from "./pages/webinars/WebinarsLiveSessions";
import WebinarsLiveSessionsNeet from "./pages/webinars/WebinarsLiveSessionsNeet";
import WebinarsNeetNcertDecode from "./pages/webinars/WebinarsNeetNcertDecode";
import PypjeeMain2023 from "./pages/PypjeeMain2023";
import PypjeeMain2024 from "./pages/PypjeeMain2024";
import PypjeeMainApril2023 from "./pages/PypjeeMainApril2023";
import NsoResult from "./pages/OlympiadResult/NsoResult";
import IoqmResult from "./pages/OlympiadResult/IoqmResult";
import RmoResult from "./pages/OlympiadResult/RmoResult";
import VvmResult from "./pages/OlympiadResult/VvmResult";
import ImoResult from "./pages/OlympiadResult/ImoResult";
import IeoResult from "./pages/OlympiadResult/IeoResult";
import IeoResult2023 from "./pages/OlympiadResult/IeoResult2023";
import UieoResult from "./pages/OlympiadResult/UieoResult";
import IssoResult from "./pages/OlympiadResult/IssoResult";
import UimoResult from "./pages/OlympiadResult/UimoResult";
import NstseResult from "./pages/OlympiadResult/NstseResult";
//Login Page
import Login from "./components/Login/Login";
import Singup from "./components/Login/Singup";
import ProfileData from "./components/Login/ProfileData";
import AppQR from "./pages/AppQR";
import JeeMain2ResultPage from "./pages/JeeMainResult/JeeMain2ResultPage";
import JEEAdvancedResult from "./pages/JeeMainResult/JEEAdvancedResult";
import JEEScholarship from "./pages/JeeMainResult/JEEScholarship";
import NEETScholarship from "./pages/JeeMainResult/NEETScholarship";
import JEEAdvancedScholarship from "./pages/JeeMainResult/JEEAdvancedScholarship";
import CbseResultClassTen from "./pages/CbseResult/CbseResultClassTen";
import NeetUgResult from "./pages/NeetUgResult/NeetUgResult";
import LinkExpiry from "./pages/LinkExpiry";
import IosResult2023 from "./pages/OlympiadResult/IosResult2023";
import NsoResult2023 from "./pages/OlympiadResult/NsoResult2023";
import ImoResult2023 from "./pages/OlympiadResult/ImoResult2023";
import IoelResult2023 from "./pages/OlympiadResult/IoelResult2023";
import IomResult2023 from "./pages/OlympiadResult/IomResult2023";

const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow2: true,
      userInfo: null,
      reloadCart: false,
    };
    // if (localStorage.getItem("userWID")) {
    //   this.state.userInfo = JSON.parse(
    //     localStorage.getItem("userWID")
    //   ).results[0];
    // }
    ////console.log(this.state.userInfo);
  }
  handleLoading(status) {
    this.setState({ reloadCart: false, loading: status });
    ////console.log(status);
  }
  handleChildClick(resp) {
    ////console.log("count-1-", resp);
    if (resp === "reloadCarts") {
      this.setState({ reloadCart: true });
      ////console.log(resp);
    } else {
      this.setState({ reloadCart: false, userInfo: resp });
    }
    // ////console.log(this.state.userInfo);
  }

  render() {

      
    return (
      <>
       
      <div className='App'>
        <React.StrictMode>
          <BrowserRouter>
            <ScrollToTop />
            <div>
              {this.state.loading && (
                <div className='loading-screen'>
                  <img src={loadingimg} alt='Loading Image' />
                </div>
              )}
              <Header handelEvent={this.state.userInfo} reloadCart={this.state.reloadCart} />
              <div className='plus-container-wrapper'>
                <Switch>
                  <Route path={"/"} exact>
                    <Home changeLoading={this.handleLoading.bind(this)} />
                  </Route>
                  <Route path={"/app/mobile-app"} exact>
                    <AppQR />
                  </Route>
                  <Route path={"/login"} render={props => <Login {...props} exact />} />
                  <Route path='/sign-up' render={props => <Singup {...props} exact />} />
                  <Route path='/user-details' render={props => <ProfileData {...props} exact />} />

                  <Route path={"/home-2"} exact>
                    <Home2 changeLoading={this.handleLoading.bind(this)} />
                  </Route>
                  <Route path={"/about-us"} exact>
                    <AboutUs />
                  </Route>
                  <Route path={"/jeemain23-s1result"} exact>
                    <ResultPage />
                  </Route>
                  <Route path={"/results/jee-main/2023"} exact>
                    <ResultPage />
                  </Route>
                  <Route path={"/results/jee-main-session-1/2023"} exact>
                    <ResultPage />
                  </Route>
                  <Route path={"/results/jee-main-session-2/2023"} exact>
                    <JeeMain2ResultPage />
                  </Route>
                  <Route path={"/results/jee-advanced"} exact>
                    <JEEAdvancedResult />
                  </Route>
                  <Route path={"/results/neet-ug"} exact>
                    <NeetUgResult />
                  </Route>
                  <Route path={"/results/cbse-class-x"} exact>
                    <CbseResultClassTen />
                  </Route>
                  <Route path={"/scholarships/jee"} exact>
                    <JEEScholarship />
                  </Route>
                  <Route path={"/scholarships/neet"} exact>
                    <NEETScholarship />
                  </Route>
                  <Route path={"/scholarships/jee-advanced"} exact>
                    <JEEAdvancedScholarship />
                  </Route>
                  <Route path={"/results/olympiad/nso"} exact>
                    <NsoResult />
                  </Route>
                  <Route path={"/results/olympiad-2023/ioqm"} exact>
                    <IoqmResult />
                  </Route>
                  <Route path={"/results/olympiad-2023/rmo"} exact>
                    <RmoResult />
                  </Route>
                  <Route path={"/results/olympiad-2023/vvm"} exact>
                    <VvmResult />
                  </Route>
                  <Route path={"/results/olympiad/imo"} exact>
                    <ImoResult />
                  </Route>
                  <Route path={"/results/olympiad/ieo"} exact>
                    <IeoResult />
                  </Route>
                  <Route path={"/results/olympiad-2023/ieo"} exact>
                    <IeoResult2023 />
                  </Route>
                  <Route path={"/results/olympiad-2023/ios"} exact>
                    <IosResult2023 />
                  </Route>
                  <Route path={"/results/olympiad-2023/nso"} exact>
                    <NsoResult2023 />
                  </Route>
                  <Route path={"/results/olympiad-2023/imo"} exact>
                    <ImoResult2023 />
                  </Route>
                  <Route path={"/results/olympiad-2023/ioel"} exact>
                    <IoelResult2023 />
                  </Route>
                  <Route path={"/results/olympiad-2023/iom"} exact>
                    <IomResult2023 />
                  </Route>
                  <Route path={"/results/olympiad/uieo"} exact>
                    <UieoResult />
                  </Route>
                  <Route path={"/results/olympiad/isso"} exact>
                    <IssoResult />
                  </Route>
                  <Route path={"/results/olympiad/uimo"} exact>
                    <UimoResult />
                  </Route>
                  <Route path={"/results/olympiad/nstse"} exact>
                    <NstseResult />
                  </Route>
                  <Route path={"/livesessions"} exact>
                    <WebinarsLiveSessions />
                  </Route>
                  <Route path={"/sessions"} exact>
                    <WebinarsLiveSessions />
                  </Route>
                  <Route path={"/sessions/neet"} exact>
                    <WebinarsLiveSessionsNeet />
                  </Route>
                  <Route path={"/neet/ncertdecode"} exact>
                    <WebinarsNeetNcertDecode />
                  </Route>
                  <Route path={"/Linkexpired"} exact>
                    <LinkExpiry />
                  </Route>
                  <Route path={"/transfer-policies"} exact>
                    <ImportantPoints />
                  </Route>
                  <Route path={"/about-us/:id"} exact component={AboutUs} />
                  <Route path={"/exam"} exact>
                    <Exam />
                  </Route>
                  <Route path={"/exam/:slug"} exact component={Exam} />
                  <Route path={"/testimonials"} exact>
                    <Testimonials />
                  </Route>
                  <Route path={"/cart"} exact>
                    <Cart onChange={this.handleChildClick.bind(this)} />
                  </Route>
                  <Route path={"/ecom/cart"} exact>
                    <EcomCart onChange={this.handleChildClick.bind(this)} />
                  </Route>
                  <Route path={"/auth/:page?"} exact>
                    <AuthComponent onChange={this.handleChildClick.bind(this)} />
                  </Route>
                  {/* <Route path={"/courses/:slug"} exact render={(props) =>
                <Courses onChange={this.handleChildClick.bind(this)} changeLoading={this.handleLoading.bind(this)} {...props} />} /> */}
                  {/* <Route path={"/courses"} exact render={(props) =>
                <Courses onChange={this.handleChildClick.bind(this)} changeLoading={this.handleLoading.bind(this)} {...props} />} /> */}
                  <Route
                    path={"/courses/:slug"}
                    exact
                    render={props => <EcomCourses onChange={this.handleChildClick.bind(this)} {...props} />}
                  />
                  <Route
                    path={"/courses"}
                    exact
                    render={props => <EcomCourses onChange={this.handleChildClick.bind(this)} {...props} />}
                  />
                  <Route
                    path={"/online-test-series"}
                    exact
                    render={props => <EcomCourses onChange={this.handleChildClick.bind(this)} {...props} />}
                  />
                  <Route path={"/ecom/courses"} exact render={props => <Redirect to='/courses' />} />
                  {/*  <Redirect to="/" />*/}
                  <Route path={"/ecom/order/status/:orderId?"} exact render={props => <EcomSuccess />} />
                  <Route path={"/propelld/success/:courseId"} exact render={props => <Success />} />
                  <Route path={"/payment/status/:orderId"} exact render={props => <ThankyouPage />} />
                  <Route path='/course-infromation' render={props => <CourseInfromation {...props} exact />} />


                  <Route
                    path={"/ecom/order-installment/status/:installmentId?"}
                    exact
                    render={props => <EcomInstallmentStatus />}
                  />
                  <Route path={"/ecom/checkout/:orderId?"} exact render={props => <EcomCheckout />} />
                  <Route path={"/ecom/Propelld/checkout/:orderId?"} exact render={props => <EcomCheckoutCopy />} />
                  {/* Order List Component */}
                  <Route path={"/profile"} exact>
                    {" "}
                    <EcomOrder />{" "}
                  </Route>
                  {/* Refund Orders Route Start*/}
                  <Route path={"/ecom/refund"} exact>
                    <RefundOrders />
                  </Route>
                  {/* Previous Year Paper */}
                  <Route path={"/previous-year-papers"} exact>
                    <PreviousYearPapers />
                  </Route>
                  {/* Previous Year Paper Route End */}
                  {/* <Route path={"/courses-details/:id"} exact render={(props) => <CoursesDetails handleCartCount={this.handleChildClick.bind(this)} changeLoading={this.handleLoading.bind(this)} {...props} />} 
              /> */}
                  <Route
                    path={"/ecom/courses-details/:slug"}
                    exact
                    render={props => <EcomCoursesDetails onChange={this.handleChildClick.bind(this)} {...props} />}
                  />
                  <Route
                    path={"/courses-details/:slug"}
                    exact
                    render={props => <EcomCoursesDetails onChange={this.handleChildClick.bind(this)} {...props} />}
                  />
                  {/* <Route path={"/profile"} exact>
                <Profile />
              </Route> */}
                  <Route path={"/edit-profile"} exact>
                    <EditProfile />
                  </Route>
                  <Route path={"/success-stories"} exact>
                    <SuccessStories />
                  </Route>
                  <Route path={"/app-features"} exact>
                    <DigitalFeatures />
                  </Route>
                  <Route path={"/free-resource/pyp-jee-main"} exact>
                    <FreeResourceJeeMain />
                  </Route>
                  <Route path={"/free-resource/pyp-jee-advanced"} exact>
                    <FreeResourceJeeAdvanced />
                  </Route>
                  <Route path={"/free-resource/pyp-neet"} exact>
                    <FreeResourceNeetUG />
                  </Route>
                  <Route path={"/pyp-jee-new-main"} exact>
                    <FreeResourceNewJeeMain />
                  </Route>
                  <Route path={"/free-resource/pyp-jee-main-2022"} exact>
                    <PypjeeMain2022 />
                  </Route>
                  <Route path={"/free-resource/pyp-jee-main-2023"} exact>
                    <PypjeeMain2023 />
                  </Route>
                  <Route path={"/free-resource/pyp-jee-main-january-2024"} exact>
                    <PypjeeMain2024 />
                  </Route>
                  <Route path={"/free-resource/pyp-jee-main-april-2023"} exact>
                    <PypjeeMainApril2023 />
                  </Route>
                  <Route path={"/free-resource/neet-papers-2023"} exact>
                    <PypNeetPapers2023 />
                  </Route>
                  <Route path={"/free-resource/neet-ug-manipur-papers-2023"} exact>
                    <NeetUgManipurPapers2023 />
                  </Route>
                  <Route path={"/free-resource/pyp-jee-advanced-2022"} exact>
                    <FreeResourceJeeAdvanced2022 />
                  </Route>
                  <Route path={"/online-test-series-major"} exact>
                    <FreemiumMajorCourse />
                  </Route>
                  <Route path={"/free-online-test-series"} exact>
                    <FreemiumMajorCourse />
                  </Route>

                  {/* study Material */}
                  {/* <Route path={"/study-material/pncf"} exact>
                <Pncf />
              </Route> */}
                  <Route path={"/study-material/neet-enthusiast"} exact>
                    <NeetEnthusiast />
                  </Route>
                  <Route path={"/study-material/jee-main-enthusiast"} exact>
                    <JeeMainEnthusiast />
                  </Route>
                  <Route path={"/study-material/jee-advanced-enthusiast"} exact>
                    <JeeAdvancedEnthusiast />
                  </Route>
                  <Route path={"/study-material/jee-advanced-leader"} exact>
                    <JeeAdvancedLeader />
                  </Route>
                  <Route path={"/study-material/neet-leader"} exact>
                    <NeetLeader />
                  </Route>
                  <Route path={"/study-material/PNCFCLASS-6"} exact>
                    <PNCFCLASS6 />
                  </Route>
                  <Route path={"/study-material/PNCFCLASS-7"} exact>
                    <PNCFCLASS7 />
                  </Route>
                  <Route path={"/study-material/PNCFCLASS-8"} exact>
                    <PNCFCLASS8 />
                  </Route>
                  <Route path={"/study-material/PNCFCLASS-9"} exact>
                    <PNCFCLASS9 />
                  </Route>
                  <Route path={"/study-material/PNCFCLASS-10"} exact>
                    <PNCFCLASS10 />
                  </Route>
                  <Route path={"/study-material/class-6-olympiad"} exact>
                    <OLYMPIADCLASS6 />
                  </Route>
                  <Route path={"/study-material/class-7-olympiad"} exact>
                    <OLYMPIADCLASS7 />
                  </Route>
                  <Route path={"/study-material/class-8-olympiad"} exact>
                    <OLYMPIADCLASS8 />
                  </Route>
                  <Route path={"/study-material/class-9-olympiad"} exact>
                    <OLYMPIADCLASS9 />
                  </Route>
                  <Route path={"/study-material/class-10-olympiad"} exact>
                    <OLYMPIADCLASS10 />
                  </Route>
                  <Route path={"/study-material/jee-adv-nurture"} exact>
                    <JeeAdvNurture />
                  </Route>
                  <Route path={"/study-material/jee-main-nurture"} exact>
                    <JeeMainNuture />
                  </Route>
                  <Route path={"/study-material/neet-nurture"} exact>
                    <NeetNuture />
                  </Route>
                  <Route path={"/study-material/foundation-iit"} exact>
                    <FoundationIIT />
                  </Route>
                  <Route path={"/study-material/foundation-medical"} exact>
                    <FoundationMedical />
                  </Route>
                  {/* Online Test Series */}
                  <Route path={"/onlinetestseries-neet"} exact>
                    <OnlineTestSeriesNeet />
                  </Route>
                  <Route path={"/scholarships/neet"} exact>
                    <NEETScholarship />
                  </Route>
                  <Route path={"/onlinetestseries-jeemain"} exact>
                    <OnlineTestSeriesJeeMain />
                  </Route>
                  <Route path={"/onlinetestseries-jeeadvance"} exact>
                    <OnlineTestSeriesJeeMainAdvanced />
                  </Route>
                  <Route path={"/onlinetestseries-pncf"} exact>
                    <OnlineTestSeriesPncf />
                  </Route>
                  <Route path={"/learning-app"} exact>
                    <LearningApp />
                  </Route>
                  <Route path={"/contact-us"} component={ContactUs} />
                  <Route path={"/jobs"} component={Jobs} />
                  <Route path={"/policies"} component={Policies} />
                  <Route path={"/refund-policy"} component={RefundPolicy} />
                  <Route path={"/t&c"} component={TremsAndConditions} />
                  <Route path={"/form-submited-success"} component={FormSuccess} />
                  <Route path={"/articles"} component={Articles} />
                  <Route
                    path={"/article-details/:slug"}
                    exact
                    render={props => <ArticleDetails onChange={this.handleChildClick.bind(this)} {...props} />}
                  />
                  <Route component={PageNotFound} />
                </Switch>
              </div>
              <Footer />
              <ToastContainer delay={3000} position={"bottom-right"} />
            </div>
          </BrowserRouter>
        </React.StrictMode>
      </div>
      </>
    );
  }
}
export default App;

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(////console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();