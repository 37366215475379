import React, { useEffect, useState } from "react";

import imgCart from "../images/cart-header.png";
import profile from "../images/profile.png";
import imgContact from "../images/contact-header.png";
import logo from "../images/logo-new-r.svg";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import MixpanelInit from "../components/Mixpanel/MixpanelInit";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);

export default function Header2(props) {
  const history = useHistory();
  const ISLOGIN = window.localStorage.getItem("IS_DASHBOARD_ICON")
    ? window.localStorage.getItem("IS_DASHBOARD_ICON")
    : false;
  const [headerData, setHeaderData] = useState({
    isLoggedIn: ISLOGIN,
    PageContent: [],
    userInfo: null,
    cartCount: 0,
    navList: [],
    ExamsNavList: [],
  });
  // console.log("test")
  const [cartCount, setCartCount] = useState(0);
  const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  const getExamList = () => {
    if (!window?.AllenDigitalFEBSiteExamList) {
      setTimeout(() => {
        getExamList();
      }, 50);
      return false;
    }
    const data = window?.AllenDigitalFEBSiteExamList;
    let examsList = data.data;
    if (examsList.length) {
      let selectedExamIndex;
      if (props.match?.params?.slug !== undefined)
        selectedExamIndex = examsList.findIndex(
          (x) => x.slug === props.match.params.slug
        );
      else
        selectedExamIndex = examsList.findIndex(
          (x) => x.id === headerData?.selectedExamId
        );
      let streams = groupBy(examsList, "stream_title");
      setHeaderData((pre) => ({ ...pre, ExamsNavList: streams }));
    } else {
      setHeaderData((pre) => ({ ...pre, ExamsNavList: [] }));
    }
  };

  const CheckTokeValidity = () => {
    const auth_token = localStorage.getItem("apiAccessToken")
      ? localStorage.getItem("apiAccessToken")
      : "";
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + auth_token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_LOGIN_URL + "auth/verfy-login", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === "failed") {
          logout();
        }
      })
      .catch((error) => console.log("error", error));
  };
  const getCartCount = () => {
    const auth_token = localStorage.getItem("apiAccessToken")
      ? localStorage.getItem("apiAccessToken")
      : "";
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + auth_token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_ECOM_URL + "cart/count", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setCartCount(result?.data?.count ? result?.data?.count : 0);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const logout = () => {
    setHeaderData((pre) => ({ ...pre, isLoggedIn: false }));
    localStorage.removeItem("apiAccessToken");
    localStorage.removeItem("IS_DASHBOARD_ICON");
    localStorage.removeItem("FORM_NO");
    localStorage.removeItem("FORM_ID");
    MixpanelInit("Reset", "", "");
    window.location = "/";
  };
  useEffect(() => {
    const ISLOGINCaheck = window.localStorage.getItem("IS_DASHBOARD_ICON")
      ? window.localStorage.getItem("IS_DASHBOARD_ICON")
      : false;
    if (ISLOGINCaheck) {
      CheckTokeValidity();
      getCartCount();
    }
    getExamList();
  }, []);

  const toggleButton = () => {
    setHeaderData((pre) => ({ ...pre, isLoggedIn: false }));
    localStorage.removeItem("apiAccessToken");
    localStorage.removeItem("IS_DASHBOARD_ICON");
    localStorage.removeItem("FORM_NO");
    localStorage.removeItem("FORM_ID");
    MixpanelInit("Reset", "", "");
    window.location = "/";
  };

  const toggleButtons = () => {
    document.getElementById("navbarNavDropdown").classList.toggle("open");
    document.getElementById("navbar-toggle").classList.toggle("open");
  };

  // const navbarToggle = document.getElementById('navbar-toggle');
  // const navbarNavDropdown = document.getElementById('navbarNavDropdown');

  // document.onclick = function(e){
  // 	if(e.target.id !== 'navbarNavDropdown' && e.target.id !== 'navbar-toggle'){
  // 		navbarNavDropdown.classList.remove('open')
  // 		navbarToggle.classList.remove('open')
  // 	}
  // }

  // const button = document.querySelector('.navbar-toggle');
  // const banner = document.querySelector('.mobile-navigation');

  // const toggleButtons = () => {
  // 		// Global open/close functions
  // 		const open = () => {
  // 			button.classList.add('open');
  // 			banner.classList.add('open');
  // 		}

  // 		const close = () => {
  // 			button.classList.remove('open');
  // 			banner.classList.remove('open');
  // 		}

  // 		// Check click on button
  // 		button.addEventListener('mousedown',  () => {

  // 			if (!button.classList.contains('open')) {
  // 				open()
  // 			}
  // 			else {
  // 				close()
  // 			}

  // 		});

  // 		// Close when user click outside
  // 		document.body.addEventListener('mousedown', (e) => {

  // 			let isClickInsideButton = button.contains(e.target);
  // 			let isClickInsideBanner = banner.contains(e.target);

  // 			if (!isClickInsideButton && !isClickInsideBanner) {
  // 				close ();
  // 			}`
  // 		});

  // 	};

  return (
    <>
      <nav className="navbar navbar-expand-lg sticky-top navbar-light header bg-white flex-column">
        <div className="header-top bg-white p-0">
          <a
            href="https://allen.in/ultimate-program-live-courses?utm_source=digitalweb&utm_medium=Ticker&utm_campaign=onlinecourse"
            target="_blank"
          >
           {/* Allen Online Program batches starting in June. To avail special fee benefits, 
            <span className="visit-allen">talk to us</span> */}
              <img className='early_bird_image mx-auto d-none d-lg-block' src={"https://cdn.digital.allen.ac.in/fe-bsite/images/top_sticky_header_desktop.jpg"} alt="Allen Digital"></img>
              <img className='early_bird_image d-none d-md-block d-lg-none' src={"https://cdn.digital.allen.ac.in/fe-bsite/images/top_sticky_header_tablet.jpg"} alt="Allen Digital"></img>
              <img className='early_bird_image d-md-none' src={"https://cdn.digital.allen.ac.in/fe-bsite/images/top_sticky_header_mobile.jpg"} alt="Allen Digital"></img>
          </a>
        </div>
        <div className="container padding-x10">
          <Link className="navbar-brand col-md-3 col-lg-2 col-6" to="/">
            <img
              src={
                headerData.PageContent?.logo?.admin_image
                  ? environment.baseUrlImg +
                    headerData.PageContent?.logo?.admin_image
                  : logo
              }
              alt=""
              className="img-fluid"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            // data-toggle='collapse'
            // data-target='#navbarNavDropdown'
            // aria-controls='navbarNavDropdown'
            // aria-expanded='false'
            // aria-label='Toggle navigation'
            // onClick={toggleButton}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="navbar-collapse nav-menu-mobile"
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav align-items-center ml-lg-auto">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Exams & PYQ
                </a>
                <div
                  className="dropdown-menu drop-down py-0 mb-2"
                  aria-labelledby="navbarDropdown"
                >
                  {Object.keys(headerData.ExamsNavList).map((key, index) => {
                    return (
                      <React.Fragment key={"nav" + index}>
                        {headerData.ExamsNavList[key].map((item, ind) => {
                          return (
                            ind === 0 && (
                              <Link
                                className={"dropdown-item drop-down-a"}
                                id="list-intro-list"
                                to={"/exam/" + item.slug}
                                key={index + ind}
                                onClick={() => {
                                  // setState({
                                  //   selectedExamDetails: item,
                                  //   selectedExamId: item.id,
                                  // });
                                }}
                              >
                                {key}
                              </Link>
                            )
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
                  <Link
                    className={"dropdown-item drop-down-a"}
                    id="list-intro-list"
                    to={"/previous-year-papers/"}
                    key={11}
                    onClick={() => {
                      // setState({
                      //   selectedExamDetails: item,
                      //   selectedExamId: item.id,
                      // });
                    }}
                  >
                    PYQ
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <a
                  /* className="nav-link dropdown-toggle" */
                  className="nav-link"
                  /* href="/courses" */
                  href="/courses"
                  id="navbarDropdown"
                  /*  role="button"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false" */
                >
                  Buy Courses
                </a>

                <div
                  className="dropdown-menu drop-down py-0"
                  aria-labelledby="navbarDropdown"
                >
                  <a
                    className="dropdown-item drop-down-a"
                    href="/courses/recorded-lectures"
                  >
                    Recorded Lectures
                  </a>

                  <a
                    className="dropdown-item drop-down-a"
                    href="/courses/live-courses"
                  >
                    Live Courses
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <a
                  /* className="nav-link dropdown-toggle" */
                  className="nav-link"
                  /* href="/courses" */
                  href="https://allen.in/online-test-series"
                  id="navbarDropdown"
                  /*  role="button"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false" */
                >
                  Online Test Series
                </a>
              </li>
              <li className="nav-item">
                <div
                  className="dropdown-menu drop-down py-0"
                  aria-labelledby="navbarDropdown"
                >
                  <a
                    className="dropdown-item drop-down-a"
                    href="/courses/recorded-lectures"
                  >
                    Recorded Lectures
                  </a>

                  <a
                    className="dropdown-item drop-down-a"
                    href="/courses/live-courses"
                  >
                    Live Courses
                  </a>
                </div>
              </li>

              {/* Free Resource Starts */}

              {/* Free Resource Ends */}

              {/* results  start*/}
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  /*  className="nav-link" */
                  /* href="/courses" */
                  /*  href="/previous-year-papers" */
                  id="navbarDropdown"
                  /* role="button" */
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Results
                </a>
                <div
                  className="dropdown-menu drop-down py-0"
                  aria-labelledby="navbarDropdown"
                >
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      /*  className="nav-link" */
                      /* href="/courses" */
                      /*  href="/previous-year-papers" */
                      id="navbarDropdown"
                      /* role="button" */
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      JEE Main
                    </a>

                    <div
                      className="dropdown-menu drop-down py-0"
                      aria-labelledby="navbarDropdown"
                    >
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          id="navbarDropdown"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          2023
                        </a>
                        <div
                          className="dropdown-menu drop-down py-0"
                          aria-labelledby="navbarDropdown"
                        >
                          <a
                            className="dropdown-item drop-down-a"
                            href="/results/jee-main-session-1/2023"
                          >
                            Session 1
                          </a>
                          <a
                            className="dropdown-item drop-down-a"
                            href="/results/jee-main-session-2/2023"
                          >
                            Session 2
                          </a>
                        </div>
                      </li>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className={"nav-link dropdown-toggle arrow-hide"}
                      to={"/results/jee-advanced"}
                      key={11}
                      onClick={() => {}}
                    >
                      JEE Advanced{" "}
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className={"nav-link dropdown-toggle arrow-hide"}
                      to={"/results/neet-ug"}
                      key={11}
                      onClick={() => {}}
                    >
                      NEET-UG
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      /*  className="nav-link" */
                      /* href="/courses" */
                      /*  href="/previous-year-papers" */
                      id="navbarDropdown"
                      /* role="button" */
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Olympiad
                    </a>
                    <div
                      className="dropdown-menu drop-down py-0"
                      aria-labelledby="navbarDropdown"
                    >
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          id="navbarDropdown"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          2022 - 2023
                        </a>
                        <div
                          className="dropdown-menu drop-down py-0"
                          aria-labelledby="navbarDropdown"
                        >
                          <a
                            className="dropdown-item drop-down-a"
                            href="/results/olympiad/nso"
                          >
                            NSO
                          </a>
                          <a
                            className="dropdown-item drop-down-a"
                            href="/results/olympiad/imo"
                          >
                            IMO
                          </a>
                          <a
                            className="dropdown-item drop-down-a"
                            href="/results/olympiad/ieo"
                          >
                            IEO
                          </a>
                          <a
                            className="dropdown-item drop-down-a"
                            href="/results/olympiad/uieo"
                          >
                            UIEO
                          </a>
                          <a
                            className="dropdown-item drop-down-a"
                            href="/results/olympiad/nstse"
                          >
                            NSTSE
                          </a>
                          <a
                            className="dropdown-item drop-down-a"
                            href="/results/olympiad/uimo"
                          >
                            UIMO
                          </a>
                          <a
                            className="dropdown-item drop-down-a"
                            href="/results/olympiad/isso"
                          >
                            ISSO
                          </a>
                        </div>
                      </li>
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          id="navbarDropdown"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          2023 - 2024
                        </a>
                        <div
                          className="dropdown-menu drop-down py-0"
                          aria-labelledby="navbarDropdown"
                        >
                          <a
                            className="dropdown-item drop-down-a"
                            href="/results/olympiad-2023/ioqm"
                          >
                            IOQM
                          </a>
                          <a
                            className="dropdown-item drop-down-a"
                            href="/results/olympiad-2023/rmo"
                          >
                            RMO
                          </a>
                          <a
                            className="dropdown-item drop-down-a"
                            href="/results/olympiad-2023/vvm"
                          >
                            VVM
                          </a>
                          <a
                            className="dropdown-item drop-down-a"
                            href="/results/olympiad-2023/ieo"
                          >
                            IEO
                          </a>
                          <a
                            className="dropdown-item drop-down-a"
                            href="/results/olympiad-2023/nso"
                          >
                            NSO
                          </a>
                          <a
                            className="dropdown-item drop-down-a"
                            href="/results/olympiad-2023/imo"
                          >
                            IMO
                          </a>
                          <a
                            className="dropdown-item drop-down-a"
                            href="/results/olympiad-2023/ios"
                          >
                            IOS
                          </a>
                          <a
                            className="dropdown-item drop-down-a"
                            href="/results/olympiad-2023/iom"
                          >
                            IOM
                          </a>
                          <a
                            className="dropdown-item drop-down-a"
                            href="/results/olympiad-2023/ioel"
                          >
                            IOEL
                          </a>
                        </div>
                      </li>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className={"nav-link dropdown-toggle arrow-hide"}
                      to={"/results/cbse-class-x"}
                      key={11}
                      onClick={() => {}}
                    >
                      CBSE Class X{" "}
                    </Link>
                  </li>
                </div>
              </li>
              {/* result end */}
              {/* <li className="nav-item">
                  <Link to="/courses" className="nav-link">
                    Buy Courses
                  </Link>
                </li> */}
              {/* <li className="nav-item">
                  <Link to="/success-stories" className="nav-link">
                    Success Stories
                  </Link>
                </li> */}
              {headerData.isLoggedIn && (
                <li className="nav-item mb-1">
                  <button
                    className="btn btn-outline-green-header m-0"
                    type="button"
                    onClick={logout}
                  >
                    Logout
                  </button>
                </li>
              )}
              {!headerData.isLoggedIn && (
                <>
                  <li className="nav-item">
                    <button
                      className="btn btn-outline-green-header mobile-mt-5px"
                      type="button"
                      onClick={(e) =>
                        history.push("/login", {
                          from: window.location.pathname,
                        })
                      }
                      /*   data-toggle="modal" data-target="#myModal" */
                    >
                      Login / Sign Up
                    </button>
                  </li>
                </>
              )}

              <li className="nav-item ml-lg-3">
                <ul className="list-inline">
                  {headerData.isLoggedIn && (
                    <li className="list-inline-item cart-item">
                      <Link to="/ecom/cart" className="relative">
                        <img src={imgCart} alt="" width="35px" />
                        {cartCount !== 0 && (
                          <span className="cart-badge">{cartCount}</span>
                        )}
                      </Link>
                    </li>
                  )}
                  <li className="list-inline-item">
                    <a href="tel:9513736499">
                      <img src={imgContact} alt="" width="35px" />
                    </a>
                  </li>

                  {/*  <li className="list-inline-item">
                      <a href="">
                        <img src={imgSearch} alt="" width="35px" />
                      </a>
                    </li> */}

                  {headerData.isLoggedIn && (
                    <li className="list-inline-item ">
                      <Link to="/profile" /*  onClick={doProfile} */>
                        <img src={profile} alt="" width="35px" />
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* popup starts*/}
      {/* <div className="modal fade" id="myModal" role="dialog">
    <div className="modal-dialog pop-center">
    
     
      <div className="modal-content border-radius-pop">
        <div className="modal-header bg-yellow">
         
        </div>
        <div className="modal-body padding-none">
        <section className="d-flex justify-content-center">
          <div id="maintenance-pop">
          <button type="button" className="close" data-dismiss="modal">&times;</button>
          <img src={maintenance} />
          <h1><b>OOPS, SORRY!!!</b></h1>
          <p>The service you are requesting is down as we are performing a maintenance activity. We're very sorry for the inconvenience.</p>
         
          </div>
          
        </div>
      </div> */}
      {/* Popup ends */}

      {/* Redirect aci site  */}
      <div
        className="modal fade"
        id="exampleModal1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog pop-center" role="document">
          <div className="modal-content border-radius-pop">
            <div className="modal-body padding-none" style={{ padding: "0px" }}>
              <section className="d-flex justify-content-center">
                {/* <button type="button" className="close" data-dismiss="modal">X</button> */}
                <div id="maintenance-pop">
                  <img
                    src={`${environment.baseUrlImg}attachment-1654175224510.svg`}
                  />
                  {/* <img src={maintenance} /> */}
                  {/* <h1><b>OOPS, SORRY!!!</b></h1> */}
                  {/* <h1><b>OOPS SORRY!!!</b></h1> */}

                  <p>
                    <b>Dear student</b>
                    <br /> We have temporarily restricted e-commerce services on
                    the allendigital.in portal. You may continue with your
                    enrollment process through the{" "}
                    <a href="https://www.allen.ac.in/">allen.ac.in</a> portal.
                  </p>
                  <a
                    href="https://www.allen.ac.in/"
                    className="btn btn-success"
                    style={{ borderRadius: "4px !important" }}
                  >
                    Click here to navigate to allen.ac.in
                  </a>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
