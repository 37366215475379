import React from "react";
// import { environment } from "../../Services/HttpData";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
export default function RazorPayApiInti(props) {
  const auth_token = localStorage.getItem("apiAccessToken")
    ? localStorage.getItem("apiAccessToken")
    : "";
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const getNewCourse = async () => {
    let userDetails;
    const requestOptionsUser = {
      method: "GET",
      headers: {
        Authorization: "Basic " + auth_token,
      },
    };
    await fetch(
      environment.ecomApiUrl + "user/detail",
      requestOptionsUser
    ).then((resp) => {
      // fetching api url for courses listing
      resp.json().then((result) => {
        //setCartItems(result.data.items);  // set result in state this will set current/updated value of setProduct to product
        userDetails = result.data;
        //  console.log(userDetails);
      });
    });
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + auth_token,
      },
      body: JSON.stringify({
        type: "razorpay",
      }),
    };
    fetch(props?.ticket_data.button_url, requestOptions).then((resp) => {
      // fetching api url for courses listing
      resp.json().then((result) => {
        // console.log(result);
        //setOrderId(result.data.order_id);
        if (result?.data?.order_id) {
          // setResponse(result.data.gateway_responce);
          // if (type == "razorpay") {
          let options = {
            key: environment.razorpayKey,
            //"amount": 100.00,
            currency: "INR",
            name: props?.ticket_data?.course_name_to,
            // description: "",
            image:
              environment.baseUrlImg + "attachment-1632935528953.png",
            order_id: result?.data?.gateway_responce?.id,
            handler: function (response) {
              // console.log(response);
              const requestOptions1 = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Basic " + auth_token,
                },
                body: JSON.stringify({
                  order_id: result.data.order_id,
                  gateway_order_id: response.razorpay_order_id,
                  gateway_payment_id: response.razorpay_payment_id,
                  gateway_signature: response.razorpay_signature,
                }),
              };
              fetch(
                environment.ecomApiUrl + "student/razorpay-verify",
                requestOptions1
              ).then((resp) => {
                // fetching api url for courses listing
                resp.json().then((result1) => {
                  //   console.log(result1);
                  /* if(result1.status == "success"){ */
                  //toast.success(result1.message);
                  if (typeof orderId == "undefined") {
                    window.location =
                      "/ecom/order/status/" + result.data.order_id;
                  } else {
                    window.location =
                      "/ecom/order-installment/status/" +
                      result.data.installment_id;
                  }
                });
              });
            },
            prefill: {
              contact: userDetails.student_mobile_no,
              name: userDetails.student_first_name,
              email: userDetails.student_email,
            },
            notes: {
              // description: props?.ticket_data?.ticket_title,
              name: props.ticket_data?.course_name_to,
            },
            theme: {
              color: "#2300a3",
            },
            modal: {
              ondismiss: function () {
                //   console.log("Checkout form closed");
                if (typeof orderId == "undefined") {
                  // window.location =
                  //   "/ecom/order/status/" + result.data.order_id;
                } else {
                  //   setLoading(true);
                }
              },
            },
          };
          let razorpayObject = new window.Razorpay(options);
          //   console.log(razorpayObject);
          razorpayObject.on("payment.failed", function (response) {
            //  console.log(response);
            //   toast.success("This step of Payment Failed");
          });
          razorpayObject.open();
        } else {
        }
        //   console.log(result.data);
      });
    });
  };
  return (
    <button className="btn btn-primary" onClick={getNewCourse}>
      PayNow
    </button>
  );
}
