import React from "react";
import { Helmet } from "react-helmet";
import fullStarImage from "../images/star-fill.png";
import halfStarImage from "../images/star.png";
import liveClassImg from '../images/live-class.png';
import testSeriesImg from '../images/test-series.png';
import onlineTestImg from '../images/online-test.png';
import courseCardImg from '../images/Rectangle 1738.png';
// import { environment } from "../Services/HttpData";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class Exam extends React.Component {
  constructor(props) {
    super(props);
    //console.log(props);
    this.state = {
      PageContent: [],
      ExamsNavList: [],
      courses: [],
      selectedExamId: 1,
      selectedExamDetails: null
    };
  }
  componentDidMount() {
    this.getCourses();
    this.fetchStreamsWithExam();
    // if(this.props.match?.params?.slug!==undefined)
    //console.log(this.props.match.params.slug);
  }
  groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
  componentWillReceiveProps(props) {
    this.props = props;
    this.fetchStreamsWithExam();
    //console.log(this.props.match.params.slug);
    /* selectedExamIndex=examsList.findIndex(x => x.slug ===props.match.params.slug);
    //console.log(selectedExamIndex); */
  }
  async fetchStreamsWithExam() {
    // POST request using fetch with async/await
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ "isAdmin": '0' })
    // };
    // fetch(environment.baseUrl + 'exam_list', requestOptions)
    //     .then(async response => {
    //         // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
    //         const data = /* isJson && */ await response.json();
    //         // check for error response
    //         // //console.log('view-lists',data);
    //         if (!response.ok) {
    //             // get error message from body or default to response status
    //             const error = (data && data.message) || response.status;
    //             return Promise.reject(error);
    //         }
    //         // this.setState({ examsList: data.data });
    //         let examsList = data.data
    //         if (examsList.length) {
    //           let selectedExamIndex;
    //           if(this.props.match?.params?.slug!==undefined)
    //             selectedExamIndex=examsList.findIndex(x => x.slug ===this.props.match.params.slug);
    //           else
    //             selectedExamIndex=examsList.findIndex(x => x.id ===this.state.selectedExamId);
    //             let streams = this.groupBy(examsList, 'stream_title');
    //             /* for (let element in streams) {
    //                 // //console.log(streams[element]);
    //                 // streams[element + "all"] = streams[element];
    //                 let rowData=streams[element];
    //                 streams[element] = this.groupBy(streams[element], 'exam_title');
    //                 streams[element]["all"] = rowData;
    //             }; */
    //             this.setState({ ExamsNavList: streams,selectedExamDetails:examsList[selectedExamIndex],selectedExamId:examsList[selectedExamIndex].id });
    //             //console.log("streams", streams,"selectedExamIndex",selectedExamIndex,examsList[selectedExamIndex]);

    //         }
    //         else {
    //             this.setState({ ExamsNavList: [] });

    //         }
    //     })
    //     .catch(error => {
    //         this.setState({ errorMessage: error.toString() });
    //         console.error('There was an error!', error);
    //     });
    if (this.props.match?.params?.slug == undefined) {
      window.location = "https://allen.in/jee-advanced?utm_source=coursebutton&utm_medium=olddomain&utm_campaign=allendigital"
    } else if (this.props.match?.params?.slug == "neet") {
      window.location = "https://allen.in/neet-ug?utm_source=coursebutton&utm_medium=olddomain&utm_campaign=allendigital"
    } else if (this.props.match?.params?.slug == "jee") {
      window.location = "https://allen.in/jee-advanced?utm_source=coursebutton&utm_medium=olddomain&utm_campaign=allendigital"
    } else if (this.props.match?.params?.slug == "jee-advanced") {
      window.location = "https://allen.in/jee-advanced?utm_source=coursebutton&utm_medium=olddomain&utm_campaign=allendigital"
    } else if (this.props.match?.params?.slug == "pre-nurture") {
      window.location = "https://allen.in/grade-6-10?utm_source=coursebutton&utm_medium=olddomain&utm_campaign=allendigital"
    }
    // const getExamList = () => {
    //   if (!window?.AllenDigitalFEBSiteExamList) {
    //     setTimeout(() => {
    //       getExamList();
    //     }, 50);
    //     return false
    //   }
    //   const data = window?.AllenDigitalFEBSiteExamList;
    //   let examsList = data.data
    //   if (examsList.length) {
    //     let selectedExamIndex;
    //     if (this.props.match?.params?.slug !== undefined)
    //       selectedExamIndex = examsList.findIndex(x => x.slug === this.props.match.params.slug);
    //     else
    //       selectedExamIndex = examsList.findIndex(x => x.id === this.state.selectedExamId);
    //     let streams = this.groupBy(examsList, 'stream_title');
    //     /* for (let element in streams) {
    //         // //console.log(streams[element]);
    //         // streams[element + "all"] = streams[element];
    //         let rowData=streams[element];
    //         streams[element] = this.groupBy(streams[element], 'exam_title');
    //         streams[element]["all"] = rowData;
    //     }; */
    //     this.setState({ ExamsNavList: streams, selectedExamDetails: examsList[selectedExamIndex], selectedExamId: examsList[selectedExamIndex].id });
    //     //console.log("streams", streams,"selectedExamIndex",selectedExamIndex,examsList[selectedExamIndex]);

    //   }
    //   else {
    //     this.setState({ ExamsNavList: [] });

    //   }
    // };
    // getExamList();
  }
  async getCourses() {
    return;
    // POST request using fetch with async/await
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("accessToken")
        /* "apiKey":process.env.API_KEY,
          "rootOrg":process.env.ROOT_ORG */
      },
      body: JSON.stringify({
        url: `${process.env.REACT_APP_LMS_URL}api-gw/external-consumers/allendigital-business/content-search/v6/search/external`,
        rootOrg: environment.ROOT_ORG,
        org: environment.ROOT_ORG,
        uuid: "9714b704-0f25-4dff-bff9-61ac349d2688",
        locale: ["en"],
        pageSize: 10,
        query: "",
        didYouMean: true,
        filters: [
          {
            andFilters: [
              {
                contentType: ["Course", "Resource"],
              },
            ],
            notFilters: [],
          },
        ],
        visibleFilters: {
          learningMode: {
            displayName: "Mode",
          },
          duration: {
            displayName: "Duration",
          },
          contentType: {
            displayName: "Content Type",
          },
          complexityLevel: {
            displayName: "Level",
          },
          catalogPaths: {
            displayName: "Catalog",
          },
          sourceShortName: {
            displayName: "Source",
          },
          resourceType: {
            displayName: "Format",
          },
          concepts: {
            displayName: "Concepts",
          },
          unit: {
            displayName: "Unit",
          },
          lastUpdatedOn: {
            displayName: "Published Date",
          },
        },
        sort: [
          {
            lastUpdatedOn: "desc",
          },
        ],
      }),
    };
    fetch(environment.baseUrl + "callBusinessAPI", requestOptions)
      .then(async (response) => {
        // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        const data = /* isJson && */ await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        if (data.status === 200) {
          this.setState({ courses: data.body.result });
          // localStorage.setItem("userDetails", JSON.stringify(data.body));
        } else {
          toast.error(data.body.message);
        }
        //console.log(data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  render() {
    if (window.location.pathname == "/exam") {
      window.location =
        "https://allen.in/jee-advanced?utm_source=coursebutton&utm_medium=olddomain&utm_campaign=allendigital";
    } else if (window.location.pathname == "/exam/neet") {
      window.location =
        "https://allen.in/neet-ug?utm_source=coursebutton&utm_medium=olddomain&utm_campaign=allendigital";
    } else if (window.location.pathname == "/exam/jee") {
      window.location =
        "https://allen.in/jee-advanced?utm_source=coursebutton&utm_medium=olddomain&utm_campaign=allendigital";
    } else if (window.location.pathname == "/exam/jee-advanced") {
      window.location =
        "https://allen.in/jee-advanced?utm_source=coursebutton&utm_medium=olddomain&utm_campaign=allendigital";
    } else if (window.location.pathname == "/exam/pre-nurture") {
      window.location =
        "https://allen.in/grade-6-10?utm_source=coursebutton&utm_medium=olddomain&utm_campaign=allendigital";
    } else if (window.location.pathname == "/exam/") {
      window.location =
        "https://allen.in/jee-advanced?utm_source=coursebutton&utm_medium=olddomain&utm_campaign=allendigital";
    }
    return (
      <>


        {this.state.selectedExamDetails?.title == "NEET UG" && <Helmet>
          {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
          {<link rel="canonical" href={window.location.origin + window.location.pathname} />}
          <title>NEET-UG Online Coaching by ALLEN Digital</title>
          <meta name='description' content="Transform your learning with ALLEN Digital's NEET-UG Online Coaching. Ace the NEET-UG Exam with interactive classes, quality materials and expert guidance." />
          <meta property="og:title" content="NEET-UG Online Coaching by ALLEN Digital" />
          <meta property="og:site_name" content="ALLEN Digital" />
          <meta property="og:url" content="https://digital.allen.ac.in/exam/neet" />
          <meta property="og:description" content="Transform your learning with ALLEN Digital's NEET-UG Online Coaching. Ace the NEET-UG Exam with interactive classes, quality materials and expert guidance." />
          <meta property="og:type" content="article" />
          <meta property="og:image" content="https://content-admin-api.allendigital.in/uploads/NEET-UG.png" />
        </Helmet>}

        {this.state.selectedExamDetails?.title == "JEE (Main)" && <Helmet>
          {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
          {<link rel="canonical" href={window.location.origin + window.location.pathname} />}
          <title>JEE Main Online Coaching by ALLEN Digital</title>
          <meta name='description' content="Transform your learning with ALLEN Digital's JEE Main Online Coaching. Ace the JEE Main Exam with interactive classes, quality materials and expert guidance." />
          <meta property="og:title" content="JEE Main Online Coaching by ALLEN Digital" />
          <meta property="og:site_name" content="ALLEN Digital" />
          <meta property="og:url" content="https://digital.allen.ac.in/exam/jee" />
          <meta property="og:description" content="Transform your learning with ALLEN Digital's JEE Main Online Coaching. Ace the JEE Main Exam with interactive classes, quality materials and expert guidance." />
          <meta property="og:type" content="article" />
          <meta property="og:image" content="https://content-admin-api.allendigital.in/uploads/JEE_MAIN.png" />
        </Helmet>}

        {this.state.selectedExamDetails?.title == "JEE (Main + Advanced)" && <Helmet>
          {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
          {<link rel="canonical" href={window.location.origin + window.location.pathname} />}
          <title>JEE Advanced Online Coaching by ALLEN Digital</title>
          <meta name='description' content="Transform your learning with ALLEN Digital's JEE Advanced Online Coaching. Ace JEE Advanced with interactive classes, quality materials and expert guidance." />
          <meta property="og:title" content="JEE Main Online Coaching by ALLEN Digital" />
          <meta property="og:site_name" content="ALLEN Digital" />
          <meta property="og:url" content="https://digital.allen.ac.in/exam/jee" />
          <meta property="og:description" content="Transform your learning with ALLEN Digital's JEE Main Online Coaching. Ace the JEE Main Exam with interactive classes, quality materials and expert guidance." />
          <meta property="og:type" content="article" />
          <meta property="og:image" content="https://content-admin-api.allendigital.in/uploads/JEE_MAIN.png" />
        </Helmet>}

        {this.state.selectedExamDetails?.title?.trim() == "Pre-Nurture (Class 6th to 10th)" && <Helmet>
          {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
          {<link rel="canonical" href={window.location.origin + window.location.pathname} />}
          <title>Pre-Nurture & Career Foundation (Class 6th to 10th)</title>
          <meta name='description' content="Transform your learning with ALLEN Digital's Class 6 to 10 Online Courses. Ace the school exams with interactive classes, quality materials and expert guidance." />
          <meta property="og:title" content="Pre-Nurture & Career Foundation (Class 6th to 10th)" />
          <meta property="og:site_name" content="ALLEN Digital" />
          <meta property="og:url" content="https://digital.allen.ac.in/exam/pre-nurture" />
          <meta property="og:description" content="Transform your learning with ALLEN Digital's Class 6 to 10 Online Courses. Ace the school exams with interactive classes, quality materials and expert guidance." />
          <meta property="og:type" content="article" />
          <meta property="og:image" content="https://content-admin-api.allendigital.in/uploads/attachment-1632939540145.png" />
        </Helmet>}


        <div className="container-fluid px-0">
          <div className="banner exam-banner">
            <div className="container banner-content">
              <h3 className="page-title">{this.state.selectedExamDetails?.title}</h3>
              <p className="mx-auto w-75 banner-description" dangerouslySetInnerHTML={{ __html: this.state.selectedExamDetails?.meta_description }}></p>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row">
            <nav className="col-12 col-sm-3 mb-3">
              {Object.keys(this.state.ExamsNavList).map((key, index) => {
                return (<>
                  <h4 className="jee-tab-title">{key}</h4>
                  <div className="list-group mb-3" id="list-tab" role="tablist">

                    {this.state.ExamsNavList[key].map((item, ind) => {
                      return (<Link
                        className={"list-group-item list-group-item-action exam-list-group-item " + ((item.id === this.state.selectedExamId) ? "active" : "")}
                        id="list-intro-list"
                        to={'/exam/' + item.slug}
                        key={index + ind}
                        onClick={() => { this.setState({ selectedExamDetails: item, selectedExamId: item.id }) }}
                      >
                        {item.title}
                      </Link>
                      )
                    }
                    )}
                  </div>
                </>
                )
              }
              )}
              {/* <h3>Medical</h3>
              <div className="list-group mb-3" id="list-tab" role="tablist">
                <a
                  className="list-group-item list-group-item-action border-0"
                  id="list-intro-list"
                  data-toggle="list"
                  href="#list-intro"
                  role="tab"
                  aria-controls="intro"
                >
                  NEET(UG)
                </a>
              </div>
              <h3>Humanities</h3>
              <div className="list-group mb-3" id="list-tab" role="tablist">
                <a
                  className="list-group-item list-group-item-action border-0"
                  id="list-intro-list"
                  data-toggle="list"
                  href="#list-intro"
                  role="tab"
                  aria-controls="intro"
                >
                  CA
                </a>
                <a
                  className="list-group-item list-group-item-action border-0"
                  id="list-proud-list"
                  data-toggle="list"
                  href="#list-proud"
                  role="tab"
                  aria-controls="proud"
                >
                  CS
                </a>
              </div>
              <h3>CBSE Board</h3>
              <div className="list-group mb-3" id="list-tab" role="tablist">
                <a
                  className="list-group-item list-group-item-action border-0"
                  id="list-intro-list"
                  data-toggle="list"
                  href="#list-intro"
                  role="tab"
                  aria-controls="intro"
                >
                  Class 12th
                </a>
                <a
                  className="list-group-item list-group-item-action border-0"
                  id="list-proud-list"
                  data-toggle="list"
                  href="#list-proud"
                  role="tab"
                  aria-controls="proud"
                >
                  Class 11th
                </a>
              </div> */}
            </nav>

            <div className="col-12 col-sm-9" id="nav-tabContent">
              <section className="row" dangerouslySetInnerHTML={{ __html: this.state.selectedExamDetails?.description }}>
                {/* <div className="col-12 col-lg-8 rectangle">
                <h3>All You Need to Know about JEE Mains</h3>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita
                </p>

                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita
                </p>

                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam
                </p>
              </div>

              <div className="col-12 col-lg-8 mt-5">
                <h4 className="mb-4 font-weight-bold">
                  Lorem ipsum dolor sit amet,
                </h4>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua. At vero eos et accusam et
                  justo duo dolores et ea rebum. Stet clita
                </p>
              </div>

              <div className="d-flex flex-row flex-wrap justify-content-center mt-5">
                <div className="col-12 col-md-6">
                  <img
                    src={courseCardImg}
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="col-12 col-md-6">
                  <h4 className="mb-2 font-weight-bold">
                    Lorem ipsum dolor sit amet,
                  </h4>
                  <ul className="green-ul">
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </li>
                    <li>Nam molestie nulla sit amet elementum suscipit.</li>
                    <li>Nam molestie nulla sit amet elementum suscipit.</li>
                    <li>Aenean tempor purus vel dictum condimentum.</li>
                    <li>
                      Integer at sapien pharetra, tempus risus non, mattis
                      massa.
                    </li>
                  </ul>
                </div>
              </div> */}
              </section>

            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Exam;
